:root {
  --main-teal: #00A2C7;
  --main-blue: #00A2C7;
  --main-navy: #0D3C48;
  --new-teal-dark: #00A2C7;
  --new-blue-light: #00A2C7;
  --box-shadow-colour: #5a5d5f15;

  --panel-max-width: 800px;
  --chakra-shadows-outline: none;


  --default-font-family: system-ui, sans-serif;
  --heading-font-family: "DM_Sans", sans-serif;
}



* {
  box-sizing: border-box;
}

html {
  height: 100%;
}

:root {
  scroll-behavior: smooth;
}

body {
  --panel-max-width: 800px;
  --chakra-shadows-outline: none;
  --chakra-fonts-heading: var(--default-font-family) !important;
  --chakra-fonts-body: var(--default-font-family) !important;
  --scrollbar-width: calc(100vw - 100%);

  background-color: #fbfbfb;
  font-family: var(Bold);
  height: 100%;
}

button {
  border: 0;
}

a {
  text-decoration: none;
  color: var(--chakra-colors-smBlue-500);
}

*[data-hover] {
  text-decoration: none;
}

*[data-focus],
:focus {
  box-shadow: "none";
}

#__next {
  height: 100%;
}

h1 {
  font-size: 36px;
  margin-bottom: 45px;
  margin-top: 50px;
  padding: 0.5em 0;
  font-family: var(--default-font-family);
  font-weight: 700;
}

h2 {
  font-size: 24px;
  margin-bottom: 25px;
}

h3 {
  font-size: 18px;
}