
.radix-themes {
  color: var(--cyan-12);

  --cursor-button: pointer;
  --color-background: transparent;

  /* :is(.dark, .dark-theme),
  :is(.dark, .dark-theme) :where(.radix-themes:not(.light, .light-theme)) {
    --color-background: transparent;
  } */

  --default-font-family: system-ui, sans-serif;
  --heading-font-family: "DM_Sans", sans-serif;
}
