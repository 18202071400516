.all_container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.all_container > .content {
  flex: 1 1 auto;
  display: flex;
}
