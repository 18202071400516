.login_container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login_text {
  width: 30em;
  margin-top: 2em;
  font-family: var(--default-font-family);
  font-weight: 300;
  color: #3e4d5b;
  font-size: 1.4em;
  text-align: center;
}

.login_form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3em;
  padding-bottom: 10em;
}

.login_form > *:not(:first-child) {
  margin-top: 1em;
}

.next_button {
  border: 0;
  width: 10em;
  padding: 1em;
  border-radius: 15px;
  background: var(--main-navy);
  font-family: var(--defualt-font-family);
  font-size: 1.5em;
  color: #fff;
  text-transform: uppercase;
  filter: drop-shadow(0px 9px 11px rgba(0, 0, 0, 0.12));
  cursor: pointer;
}

.next_button:active {
  background: var(--main-blue);
}
