.fade-item {
  transition: 0.3s all ease-in-out;
  opacity: 0;
}

.fadein {
  animation: fadeIn 0.4s 1;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fadeout {
  animation: fadeOut 0.2s 1;
  animation-fill-mode: forwards;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeInAndSlide {
  from {
    opacity: 0;
    transform: translate(0px, 10px);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

.fadeInAndSlide {
  animation-name: fadeIn;
  animation-timing-function: ease-out;
  animation-duration: 0.5s;
}
