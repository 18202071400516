.sm_grid {
  border: 1px solid var(--chakra-colors-smNavy-270);
  background-color: var(--chakra-colors-smWhite-500);
  font-size: 0.9em;
}

.grid_header {
  font-family: var(--defualt-font-family);
  background-color: var(--chakra-colors-smNavy-250);
}

.sm_grid > div {
  padding: 0.8em 1em 0.8em 1em;
  font-family: var(--defualt-font-family);
}

@media screen and (max-width: 480px) {
  .sm_grid > div {
    padding: 0.8em 0.5em 0.8em 0.5em;
  }
}

.grid_row_divider {
  grid-column: span 3;
  padding: 0px 1em 0px 1em !important;
}
/* 
button[role="tab"]:not(:first-child) {
  border-left: 1px solid var(--chakra-colors-smBlack-150);
} */

/* .chakra-alert {
  border-radius: 2px !important;
} */

/* Left align text in chakra menu buttons */
.chakra-menu__menu-button > span {
  flex: 0 0 auto;
  display: flex;
}
