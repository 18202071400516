/* Layout styles */

.transcript-text {
  border-bottom: 1px solid var(--chakra-colors-smBlack-200);
}

.diarization-section:nth-child(odd) {
  background-color: var(--chakra-colors-smBlack-130);
}

.diarization-section {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: var(--chakra-space-8);
  padding-bottom: var(--chakra-space-8);
  flex-wrap: wrap;
}

.diarization-label-section {
  width: 25%;
  min-width: 150px;
  display: flex;
  flex-direction: column;
  gap: var(--chakra-space-4);
  padding-bottom: var(--chakra-space-4);
}

.speech-section {
  flex: 6;
  line-height: 2rem;
  max-width: calc(0.7 * var(--panel-max-width));
  width: 100%;
  min-width: 250px;
  align-items: center;
}

.content-center {
  justify-content: center;
}

@media screen and (max-width: 480px) {
  .diarization-section {
    display: block;
  }
}

/* Component styles */

.diarization-label {
  border: 1px solid var(--chakra-colors-smBlack-200);
  border-radius: 16px;
  width: fit-content;
  width: -moz-fit-content;
  padding-left: 6px;
  padding-right: 6px;
  font-family: var(--defualt-font-family);
  font-size: 0.9rem;
}

.timestamp-label {
  font-size: 0.8rem;
  font-weight: bold;
}

.scroll-bar-style::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}

.scroll-bar-style::-webkit-scrollbar {
  width: 8px;
  border-left: 10px;
}

.scroll-bar-style::-webkit-scrollbar-thumb {
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background-color: #999;
}

.display-custom-dict .word-custom-dict {
  text-decoration: underline;
}

.not-showing-disfluencies .capitalize-word {
  text-transform: capitalize;
}

.not-showing-disfluencies .word-disfluency {
  display: none;
}

.display-confidence .word-confidence-02 {
  color: #d82b2b;
}

.display-confidence .word-confidence-24 {
  color: #ac2d31;
}

.display-confidence .word-confidence-46 {
  color: #7f2f37;
}

.display-confidence .word-confidence-68 {
  color: #53303d;
}

.display-confidence .word-confidence-1 {
  color: #263243;
}

.profanity-inner {
  display: inherit;
}

.non-profanity-inner {
  display: none;
}

.filter-profanities .profanity-inner {
  display: none;
}

.filter-profanities .non-profanity-inner {
  display: inherit;
}

.showing-entities-written .entity-spoken {
  display: none;
}

.showing-entities-spoken .entity-written {
  display: none;
}

.diarization-purple {
  background: linear-gradient(white, white) padding-box,
    linear-gradient(var(--chakra-colors-smPurple-700), var(--chakra-colors-smBlue-500)) border-box;
  border: 1px solid transparent;
  color: var(--chakra-colors-smPurple-700);
}

.diarization-blue {
  background: linear-gradient(var(--chakra-colors-smBlue-50), var(--chakra-colors-smBlue-50)) padding-box,
    linear-gradient(var(--chakra-colors-smBlue-500), var(--chakra-colors-smBlue-300)) border-box;
  border: 1px solid transparent;
  color: var(--chakra-colors-smBlue-500);
}

.diarization-red {
  background: linear-gradient(var(--chakra-colors-smRed-50), var(--chakra-colors-smRed-50)) padding-box,
    linear-gradient(var(--chakra-colors-smRed-500), var(--chakra-colors-smOrange-400)) border-box;
  border: 1px solid transparent;
  color: var(--chakra-colors-smRed-500);
}

.diarization-orange {
  background: linear-gradient(var(--chakra-colors-smOrange-50), var(--chakra-colors-smOrange-50)) padding-box,
    linear-gradient(var(--chakra-colors-smOrange-400), var(--chakra-colors-smYellow-500)) border-box;
  border: 1px solid transparent;
  color: var(--chakra-colors-smOrange-500);
}

.diarization-green {
  background: linear-gradient(var(--chakra-colors-smGreen-50), var(--chakra-colors-smGreen-50)) padding-box,
    linear-gradient(var(--chakra-colors-smGreen-500), var(--chakra-colors-smGreen-300)) border-box;
  border: 1px solid transparent;
  color: var(--chakra-colors-smGreen-500);
}

.divider-hitbox {
  cursor: col-resize;
  align-self: stretch;
  display: flex;
  align-items: center;
  padding: 0 1rem;
}

.divider {
  height: 100%;
  border: 1px solid #808080;
}

.rightPane {
  flex: 1;
}

.splitView {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.alert-box {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
  border-radius: 1rem;
  align-items: center;
  gap: 5px;
  padding: 4px 4px 4px 14px;
  font-size: 0.875rem;
  background-color: var(--chakra-colors-smBlue-500);
  color: var(--chakra-colors-smWhite-500);
}

.alert-box>.alert-box-text {
  flex: 1;
  white-space: nowrap;
}

.alert-box>a {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 8px 3px 10px;
  gap: 4px;
  max-width: 8rem;
  background: var(--chakra-colors-smWhite-500);
  border-radius: 16px;
  border-bottom: none;
  line-height: 1.25rem;
  color: var(--chakra-colors-smBlue-500);
  font-size: 0.875rem;
  box-shadow: none;
  margin: 0;
  height: 24px;
  text-align: center;
}

.alert-box>a:hover {
  background-color: var(--chakra-colors-smBlue-500);
  color: var(--chakra-colors-smWhite-500);
  border: 1px var(--chakra-colors-smWhite-500) solid;
}

@media screen and (max-width: 600px) {
  .alert-box {
    flex-direction: column;
    margin-left: 24px;
    margin-right: 24px;
  }

  .alert-box>.alert-box-text {
    text-align: center;
    white-space: normal;
  }
}